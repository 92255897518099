<template>
    <div>
        <div v-show="nowIndex == 0">
            <div class="right_header">
                <span>项目名称</span>
                <input
                        type="text"
                        class="input_search"
                        v-model="params.keyword"
                        style="padding: 0 10px;height: 41px"
                />
                <div class="button_style" @click="search()" style="margin-left: 16px;">查询</div>
            </div>
            <div class="right_real_content">
                <Table border :columns="columns12" :data="data6" :disabled-hover="true">
                    <template slot-scope="{ row }" slot="name">
                        <strong>{{ row.name }}</strong>
                    </template>
                    <template slot-scope="{ row, index }" slot="action">
                        <Button
                                v-if="row.isUsed == 0"
                                type="primary"
                                size="small"
                                style="margin-right: 5px; color: rgb(55, 197, 207)"
                                @click="declarationGuide(row)"
                        >申报指南
                        </Button>
                        <span class="operation_span" v-if="row.isUsed  == 0">|</span>
                        <Button
                                v-if="row.isUsed  == 0"
                                type="error"
                                size="small"
                                style="color: rgb(55, 197, 207)"
                                @click="goDeclarate(row)"
                        >进入申报
                        </Button
                        >
                        <Button
                                v-if="row.isUsed  == 1"
                                type="error"
                                size="small"
                                style="color: rgb(55, 197, 207)"
                                @click="continueDeclarate(row)"
                        >继续申报
                        </Button
                        >
                        <div v-if="row.isUsed == 2">
                            -- --
                        </div>
                    </template>
                </Table>
                <Page
                        :total="total"
                        show-elevator
                        style="margin-top: 50px; float: right; margin-bottom: 20px"
                        @on-change="changePage"
                />
            </div>
        </div>

        <!--申报流程   首页-->
        <div v-show="nowIndex == 1">
            <div style="height: 370px" class="common_liucheng">
                <span>申报流程</span>
                <div class="neirong" style="padding-left: 142px">
                    <img :src="process" alt="" style="width: 85%"/>
                </div>
            </div>
            <div style="height: 370px" class="common_liucheng">
                <span>填报说明</span>
                <div class="neirong" style="padding-left: 142px">
                    <p style="line-height: 38px">
                        一、本申报书是申报项目的依据，填写内容须实事求是，表述应明确、严谨，相应栏目请填写完整。缺项或格式不符的申报书不予受理。
                    </p>
                    <p style="line-height: 38px">二、本申报书为A4纸张打印，报送一式2份。同时应发送申报书电子版。</p>
                    <p style="line-height: 38px">
                        三、组织实施条件一栏，应包括现有专家资源、平台、工作团队等条件。
                    </p>
                    <p style="line-height: 38px">
                        四、工作方案一栏，应包括项目的主要内容、基本思路和方法、实施计划及有关措施等。
                    </p>
                    <p style="line-height: 38px">五、经费使用限于申报书所列的内容。</p>
                </div>
            </div>
            <div style="height: 240px" class="common_liucheng">
                <span>模板下载</span>
                <div class="neirong" style="padding-left: 142px">
                    <span>诚信承诺书 (模板).doc</span>
                    <span class="download_span"
                    ><a
                            href="https://cnipy-public.oss-cn-shanghai.aliyuncs.com/cnipy/web-static/zndec/doc/%E8%AF%9A%E4%BF%A1%E6%89%BF%E8%AF%BA%E4%B9%A6%EF%BC%88%E6%A8%A1%E7%89%88%EF%BC%89.docx"
                    >点击下载</a
                    ></span
                    >
                    <span>申报单位意见书 (模板).doc</span>
                    <span class="download_span"
                    ><a
                            href="https://cnipy-public.oss-cn-shanghai.aliyuncs.com/cnipy/web-static/zndec/doc/%E7%94%B3%E6%8A%A5%E5%8D%95%E4%BD%8D%E6%84%8F%E8%A7%81%E4%B9%A6%EF%BC%88%E6%A8%A1%E7%89%88%EF%BC%89.docx"
                    >点击下载</a
                    ></span
                    >
                    <span>项目经费支出预算 (模板).doc</span>
                    <span class="download_span"
                    ><a
                            href="https://cnipy-public.oss-cn-shanghai.aliyuncs.com/cnipy/web-static/zndec/doc/%E9%A1%B9%E7%9B%AE%E7%BB%8F%E8%B4%B9%E9%A2%84%E7%AE%97%E8%A1%A8%EF%BC%88%E6%A8%A1%E7%89%88%EF%BC%89.docx"
                    >点击下载</a
                    ></span
                    >
                </div>
            </div>
            <div style="height: 180px" class="next_style common_liucheng">
                <div
                        style="height: 40px; width: 120px"
                        class="button_style"
                        @click="
            nowIndex = 2;
            $parent.changeHeight();
          "
                >
                    下一步
                </div>
            </div>
        </div>

        <!--申报流程 基础信息-->
        <div v-show="nowIndex == 3">
            <div style="height: 450px" class="common_liucheng">
                <span>基础信息</span>
                <div class="neirong">
                    <Form :model="basicInformationForm" :label-width="140">
                        <FormItem label="项目类别:">
                            <CheckboxGroup
                                    v-model="basicInformationForm.projectCategory"
                                    @on-change="dataChange"
                            >
                                <Checkbox label="1">重点项目</Checkbox>
                                <Checkbox label="2">一般项目</Checkbox>
                            </CheckboxGroup>
                        </FormItem>
                        <FormItem label="项目类型:">
                            <CheckboxGroup
                                    v-model="basicInformationForm.projectType"
                                    @on-change="dataChange1"
                            >
                                <Checkbox label="0">基础研究</Checkbox>
                                <Checkbox label="1">应用研究</Checkbox>
                                <Checkbox label="2">应用示范</Checkbox>
                                <Checkbox label="3">孵化项目</Checkbox>
                            </CheckboxGroup>
                        </FormItem>
                        <Row>
                            <Col :span="10">
                                <FormItem label="项目总预算:">
                                    <Input v-model="basicInformationForm.totalBudget"
                                           @blur.native.capture="isNum(basicInformationForm.totalBudget,'basicInformationForm','totalBudget','项目总预算')"></Input
                                    >&nbsp;万元
                                </FormItem>
                            </Col>
                            <Col></Col>
                            <Col :span="10">
                                <FormItem label="申请资助:">
                                    <Input v-model="basicInformationForm.applySupport"
                                           @blur.native.capture="isNum(basicInformationForm.applySupport,'basicInformationForm','applySupport','申请资助')"></Input
                                    >&nbsp;万元
                                </FormItem>
                            </Col>
                        </Row>
                        <div>
                            <FormItem label="研究截止日期:">
                                <div style="display: flex">
                                    <DatePicker
                                            :transfer="true"
                                            type="date"
                                            :options="startOption"
                                            v-model="startTime"
                                    ></DatePicker>

                                    <div style="margin-right: 40px">
                                        <span>至</span>
                                    </div>

                                    <DatePicker
                                            type="date"
                                            :transfer="true"
                                            :options="endOption"
                                            v-model="endTime"
                                    ></DatePicker>
                                </div>
                            </FormItem>
                        </div>
                    </Form>
                </div>
            </div>
            <div style="height: 520px" class="common_liucheng">
                <span>项目负责人信息</span>
                <div class="neirong">
                    <Form :model="projectLeaderForm" :label-width="140">
                        <Row>
                            <Col :span="10">
                                <FormItem label="姓名:">
                                    <Input v-model="projectLeaderForm.leaderName"></Input>
                                </FormItem>
                            </Col>
                            <Col :span="10">
                                <FormItem label="性别:">
                                    <Select v-model="projectLeaderForm.gender">
                                        <Option value="0">未知</Option>
                                        <Option value="1">男</Option>
                                        <Option value="2">女</Option>
                                    </Select>
                                </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col :span="10">
                                <FormItem label="出生年月:">
                                    <DatePicker
                                            :transfer="true"
                                            type="date"
                                            v-model="projectLeaderForm.birthday"
                                    ></DatePicker>
                                </FormItem>
                            </Col>
                            <Col :span="10">
                                <FormItem label="学历:">
                                    <Select v-model="projectLeaderForm.education">
                                        <Option value="1">大专</Option>
                                        <Option value="2">本科</Option>
                                        <Option value="3">硕士</Option>
                                        <Option value="4">博士</Option>
                                    </Select>
                                </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col :span="10">
                                <FormItem label="手机号码:">
                                    <Input v-model="projectLeaderForm.phoneNumber"></Input>
                                </FormItem>
                            </Col>
                            <Col :span="10">
                                <FormItem label="身份证号码:">
                                    <Input v-model="projectLeaderForm.idNumber"></Input>
                                </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col :span="10">
                                <FormItem label="所学专业:">
                                    <Input v-model="projectLeaderForm.major"></Input>
                                </FormItem>
                            </Col>
                            <Col :span="10">
                                <FormItem label="电子邮箱:">
                                    <Input v-model="projectLeaderForm.email"></Input>
                                </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col :span="10">
                                <FormItem label="职称:">
                                    <Input v-model="projectLeaderForm.position"></Input>
                                </FormItem>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>
            <div style="height: 380px" class="common_liucheng">
                <span>课题组主要成员</span>
                <div class="neirong">
                    <Form :model="membersForm" :label-width="140">
                        <Row>
                            <Col :span="10">
                                <FormItem label="姓名:">
                                    <Input v-model="membersForm.name"></Input>
                                </FormItem>
                            </Col>
                            <Col :span="10">
                                <FormItem label="学历:">
                                    <Select v-model="membersForm.education">
                                        <Option value="1">大专</Option>
                                        <Option value="2">本科</Option>
                                        <Option value="3">硕士</Option>
                                        <Option value="4">博士</Option>
                                    </Select>
                                </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col :span="10">
                                <FormItem label="职称:">
                                    <Input v-model="membersForm.title"></Input>
                                </FormItem>
                            </Col>
                            <Col :span="10">
                                <FormItem label="号码:">
                                    <Input v-model="membersForm.phoneNumber"></Input>
                                </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col :span="10">
                                <FormItem label="身份证号码:">
                                    <Input v-model="membersForm.idNumber"></Input>
                                </FormItem>
                            </Col>
                            <Col :span="10">
                                <FormItem label="单位名称:">
                                    <Input v-model="membersForm.companyName"></Input>
                                </FormItem>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>
            <div style="height: 390px" class="common_liucheng">
                <span>申报单位情况</span>
                <div class="neirong">
                    <Form :model="declarationUnitForm" :label-width="140">
                        <Row>
                            <Col :span="10">
                                <FormItem label="单位名称:">
                                    <Input v-model="declarationUnitForm.unitsName"></Input>
                                </FormItem>
                            </Col>
                            <Col :span="10">
                                <FormItem label="性质:">
                                    <Input v-model="declarationUnitForm.unitsCharacter"></Input>
                                </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col :span="10">
                                <FormItem label="邮编:">
                                    <Input v-model="declarationUnitForm.postcode"></Input>
                                </FormItem>
                            </Col>
                            <Col :span="10">
                                <FormItem label="社会统一信用代码:">
                                    <Input v-model="declarationUnitForm.creditCode"></Input>
                                </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col :span="23">
                                <FormItem label="通讯地址:">
                                    <Input v-model="declarationUnitForm.postalAddress"></Input>
                                </FormItem>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>
            <div style="height: 390px" class="common_liucheng">
                <span>合作单位情况</span>
                <div class="neirong">
                    <Form :model="cooperationUnitForm" :label-width="140">
                        <Row>
                            <Col :span="10">
                                <FormItem label="单位名称:">
                                    <Input v-model="cooperationUnitForm.unitsName"></Input>
                                </FormItem>
                            </Col>
                            <Col :span="10">
                                <FormItem label="性质:">
                                    <Input v-model="cooperationUnitForm.unitsCharacter"></Input>
                                </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col :span="10">
                                <FormItem label="邮编:">
                                    <Input v-model="cooperationUnitForm.postcode"></Input>
                                </FormItem>
                            </Col>
                            <Col :span="10">
                                <FormItem label="社会统一信用代码:">
                                    <Input v-model="cooperationUnitForm.creditCode"></Input>
                                </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col :span="23">
                                <FormItem label="通讯地址:">
                                    <Input v-model="cooperationUnitForm.postalAddress"></Input>
                                </FormItem>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>
            <div style="height: 410px" class="common_liucheng">
                <span>主要研究内容与经济技术指标（300字左右） </span>
                <div class="neirong">
                    <Form :model="basicInformationForm">
                        <FormItem label="">
                            <Input
                                    v-model="basicInformationForm.researchContents"
                                    type="textarea"
                                    :autosize="{ minRows: 5, maxRows: 10 }"
                            ></Input>
                        </FormItem>
                    </Form>
                </div>
            </div>
            <div style="height: 180px" class="next_style common_liucheng">
                <div
                        style="height: 40px; width: 120px"
                        class="button_style"
                        @click="nowIndex4()"
                >
                    下一步
                </div>
                <!--        <div-->
                <!--          style="-->
                <!--            height: 40px;-->
                <!--            width: 120px;-->
                <!--            background-color: #fc7c40;-->
                <!--            margin-left: 20px;-->
                <!--          "-->
                <!--          class="button_style"-->
                <!--          @click="nowIndex4()"-->
                <!--        >-->
                <!--          暂存-->
                <!--        </div>-->
            </div>
        </div>

        <!--其他信息-->
        <div v-show="nowIndex == 4">
            <Form :model="extraForm" :label-width="100">
                <div style="height: 470px" class="common_liucheng">
                    <span>项目意义</span>
                    <div class="neirong">
                        <Row>
                            <Col span="21">
                                <FormItem label="">
                                    <Input
                                            v-model="extraForm.projectSignificance"
                                            type="textarea"
                                            :autosize="{ minRows: 10 }"
                                            placeholder="着重表述实施该项目的必要性和当前自治区经济社会发展急需的程度"
                                    ></Input>
                                </FormItem>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div style="height: 470px" class="common_liucheng">
                    <span>相关领域国内外技术现状和发展趋势</span>
                    <div class="neirong">
                        <Row>
                            <Col span="21">
                                <FormItem label="">
                                    <Input
                                            v-model="extraForm.technologyStatus"
                                            type="textarea"
                                            :autosize="{ minRows: 10 }"
                                            placeholder="主要阐明相关领域国内外技术现状、专利等知识产权情况，国内外技术发展趋势等"
                                    ></Input>
                                </FormItem>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div style="height: 470px" class="common_liucheng">
                    <span>项目目标</span>
                    <div class="neirong">
                        <Row>
                            <Col span="21">
                                <FormItem label="">
                                    <Input
                                            v-model="extraForm.projectObjectives"
                                            type="textarea"
                                            :autosize="{ minRows: 10 }"
                                            placeholder="项目目标的涵盖范围要与项目名称相对应；确立的项目目标应能切实支撑项目立项的意义；目标应该明确具体，便于考核；其目标在计划实施周期内能够完成"
                                    ></Input>
                                </FormItem>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div style="height: 470px" class="common_liucheng">
                    <span>项目主要任务</span>
                    <div class="neirong">
                        <Row>
                            <Col span="21">
                                <FormItem label="">
                                    <Input
                                            v-model="extraForm.projectTasks"
                                            type="textarea"
                                            :autosize="{ minRows: 10 }"
                                            placeholder="主要包括研究重点与开发内容，项目、人才、基地统筹计划，技术关键、技术难点、创新点、实施计划。其内容应与项目目标有直接对应的支撑关系，为实现项目目标所应进行的重点研究内容不应有遗漏，也不应包括与项目目标关系度不大的内容"
                                    ></Input>
                                </FormItem>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div style="height: 470px" class="common_liucheng">
                    <span>项目预期技术、经济、社会效益及推广应用和产业化前景</span>
                    <div class="neirong">
                        <Row>
                            <Col span="21">
                                <FormItem label="">
                                    <Input
                                            v-model="extraForm.expectedProspects"
                                            type="textarea"
                                            :autosize="{ minRows: 10 }"
                                            placeholder="包括技术、经济、社会效益分析，推广应用与产业化前景分析，可能取得的专利、尤其是发明专利及知识产权分析，项目实施的风险分析"
                                    ></Input>
                                </FormItem>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div style="height: 470px" class="common_liucheng">
                    <span>项目现有研究工作基础</span>
                    <div class="neirong">
                        <Row>
                            <Col span="21">
                                <FormItem label="">
                                    <Input
                                            v-model="extraForm.researchBasis"
                                            type="textarea"
                                            :autosize="{ minRows: 10 }"
                                            placeholder="主要阐述项目成熟性。包括项目申报单位已有的研究开发经历、科研成果、科研条件、人才团队、组织机制和前期准备工作等"
                                    ></Input>
                                </FormItem>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div style="height: 470px" class="common_liucheng">
                    <span>必要的支撑条件、组织措施及实施方案</span>
                    <div class="neirong">
                        <Row>
                            <Col span="21">
                                <FormItem label="">
                                    <Input
                                            v-model="extraForm.implementationPlan"
                                            type="textarea"
                                            :autosize="{ minRows: 10 }"
                                            placeholder="主要阐明为完成该项目相关的保障措施、组织实施方案、管理措施与实施年限；企业参与度、产学研联合机制等"
                                    ></Input>
                                </FormItem>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div style="height: 470px" class="common_liucheng">
                    <span>项目的关联行动</span>
                    <div class="neirong">
                        <Row>
                            <Col span="21">
                                <FormItem label="">
                                    <Input
                                            v-model="extraForm.linkedAction"
                                            type="textarea"
                                            :autosize="{ minRows: 10 }"
                                            placeholder="重点阐述与本项目相关的基本建设、技术改造、技术引进、国际合作等落实情况，本项目与其他相关国家、自治区科技计划项目及其他工程与建设计划项目的衔接和分工"
                                    ></Input>
                                </FormItem>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div style="height: 470px" class="common_liucheng">
                    <span>项目负责人主要工作及科研经历</span>
                    <div class="neirong">
                        <Row>
                            <Col span="21">
                                <FormItem label="">
                                    <Input
                                            v-model="extraForm.personResume"
                                            type="textarea"
                                            :autosize="{ minRows: 10 }"
                                            placeholder=""
                                    ></Input>
                                </FormItem>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div style="height: 180px" class="next_style common_liucheng">
                    <div
                            style="height: 40px; width: 120px"
                            class="button_style"
                            @click="submit('1')"
                    >
                        提交
                    </div>
                    <!--          <div-->
                    <!--            style="-->
                    <!--              height: 40px;-->
                    <!--              width: 120px;-->
                    <!--              background-color: #fc7c40;-->
                    <!--              margin-left: 20px;-->
                    <!--            "-->
                    <!--            class="button_style"-->
                    <!--            @click="submit('0')"-->
                    <!--          >-->
                    <!--            暂存-->
                    <!--          </div>-->
                </div>
            </Form>
        </div>

        <!--填报信息-->
        <div v-show="nowIndex == 2">
            <div style="height: 1000px" class="common_liucheng">
                <span>申报流程</span>
                <div class="neirong">
                    <p
                            style="
              text-align: center;
              font-size: 20px;
              font-family: Microsoft YaHei;
              display: block;
              color: rgb(51, 51, 51);
            "
                    >
                        {{ this.mainTitle }}
                    </p>
                    <p
                            style="
              text-align: center;
              font-size: 20px;
              font-family: Microsoft YaHei;
              display: block;
              margin-bottom: 65px;
              color: rgb(51, 51, 51);
            "
                    >

                    </p>
                    <Form :model="fillForm" :label-width="170">
                        <FormItem label="项目名称:">
                            <Input v-model="fillForm.projectName" type="text" disabled/>
                        </FormItem>
                        <FormItem label="申报单位:">
                            <Input v-model="fillForm.declareEnter" type="text"/>
                        </FormItem>
                        <FormItem label="通讯地址:">
                            <Input v-model="fillForm.postalAddress" type="text"/>
                        </FormItem>
                        <Row>
                            <Col span="15">
                                <FormItem label="联系人:">
                                    <Input v-model="fillForm.contactUser" type="text"/>
                                </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col span="15">
                                <FormItem label="联系电话:">
                                    <Input v-model="fillForm.contactTel" type="text"/>
                                </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col span="24">
                                <FormItem label="诚信承诺书:">
                                    <Input
                                            disabled
                                            id="promise"
                                            style="width: 30%"
                                            v-model="fillForm.promise"
                                            type="text"
                                    />
                                    <Upload
                                            action=""
                                            :show-upload-list="false"
                                            :before-upload="(file) => handleUpload(file, 'promise')"
                                    >
                                        <Button style="padding-left: 20px">浏览</Button>
                                        <span style="padding-left:5px;vertical-align: text-top"
                                        >承诺书需加盖公章并扫描.jpg、.png格式上传</span
                                        >
                                    </Upload>
                                </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col span="24">
                                <FormItem label="申报单位意见书:">
                                    <Input
                                            disabled
                                            id="cooperationAgreement"
                                            style="width: 30%"
                                            v-model="fillForm.cooperationAgreement"
                                            type="text"
                                    />
                                    <Upload
                                            multiple
                                            action=""
                                            :show-upload-list="false"
                                            :format="['jpg', 'png']"
                                            :before-upload="
                      (file) => handleUpload(file, 'cooperationAgreement')
                    "
                                    >
                                        <Button style="padding-left: 20px">浏览</Button>
                                        <span style="padding-left:5px;vertical-align: text-top"
                                        >承诺书需加盖公章并扫描.jpg、.png格式上传</span
                                        >
                                    </Upload>
                                </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col span="24">
                                <FormItem label="经费预算表:">
                                    <Input
                                            disabled
                                            id="budget"
                                            v-model="fillForm.budget"
                                            type="text"
                                            style="width: 30%"
                                    />

                                    <Upload
                                            action=""
                                            :show-upload-list="false"
                                            :format="['jpg', 'png']"
                                            :before-upload="(file) => handleUpload(file, 'budget')"
                                    >
                                        <Button style="padding-left: 20px">浏览</Button>
                                        <span style="padding-left:5px;vertical-align: text-top"
                                        >承诺书需加盖公章并扫描.jpg、.png格式上传</span
                                        >
                                    </Upload>
                                </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col span="24">
                                <FormItem label="单位合作协议（可选）:">
                                    <Input
                                            disabled
                                            id="proposal"
                                            v-model="fillForm.proposal"
                                            type="text"
                                            style="width: 30%"
                                    />
                                    <Upload
                                            action=""
                                            :show-upload-list="false"
                                            :format="['jpg', 'png']"
                                            :before-upload="(file) => handleUpload(file, 'proposal')"
                                    >
                                        <Button style="padding-left: 20px">浏览</Button>
                                        <span style="padding-left:5px;vertical-align: text-top"
                                        >当为两个及以上的单位联合申报项目时需要提交</span
                                        >
                                    </Upload>
                                </FormItem>
                            </Col>
                        </Row>
                    </Form>
                    <div
                            style="height: 180px; border-bottom: none"
                            class="next_style common_liucheng"
                    >
                        <div
                                style="height: 40px; width: 120px; cursor: pointer"
                                class="button_style"
                                @click="nowIndex3()"
                        >
                            下一步
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!--战略政策 基础信息-->
        <div v-show="nowIndex == 7">
            <div style="height: 400px" class="common_liucheng">
                <span>基础信息</span>
                <div class="neirong">
                    <Form :model="strategyBasicFrom" :label-width="170">
                        <FormItem label="项目类别:">
                            <CheckboxGroup
                                    v-model="strategyBasicFrom.projectCategory"
                                    @on-change="dataChange2"
                            >
                                <Checkbox label="1">重点项目</Checkbox>
                                <Checkbox label="2">一般项目</Checkbox>
                            </CheckboxGroup>
                        </FormItem>
                        <Row>
                            <Col span="10">
                                <FormItem label="预期成果形式:">
                                    <Input
                                            v-model="strategyBasicFrom.expectedResults"
                                            type="text"
                                    />
                                </FormItem>
                            </Col>
                            <Col span="10">
                                <FormItem label="成果字数:">
                                    <Input v-model="strategyBasicFrom.achievementsWords" type="text"
                                           @blur.native.capture="isNum(strategyBasicFrom.achievementsWords,'strategyBasicFrom','achievementsWords','成果字数')"
                                    />
                                </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col span="10">
                                <FormItem label="申请经费:">
                                    <Input
                                            v-model="strategyBasicFrom.applyFunds"
                                            type="text"
                                            @blur.native.capture="isNum(strategyBasicFrom.applyFunds,'strategyBasicFrom','applyFunds','申请经费')"
                                    />&nbsp;万元
                                </FormItem>
                            </Col>
                            <Col span="10">
                                <FormItem label="计划完成时间:">
                                    <DatePicker
                                            :transfer="true"
                                            type="date"
                                            v-model="strategyBasicFrom.planCompletedTime"
                                    />
                                </FormItem>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>
            <div style="height: 640px" class="common_liucheng">
                <span>项目负责人信息</span>
                <div class="neirong">
                    <Form :model="strategyProjectLeaderForm" :label-width="140">
                        <Row>
                            <Col :span="10">
                                <FormItem label="姓名:">
                                    <Input v-model="strategyProjectLeaderForm.leaderName"></Input>
                                </FormItem>
                            </Col>
                            <Col :span="10">
                                <FormItem label="性别:">
                                    <Select v-model="strategyProjectLeaderForm.gender">
                                        <Option value="0">未知</Option>
                                        <Option value="1">男</Option>
                                        <Option value="2">女</Option>
                                    </Select>
                                </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col :span="10">
                                <FormItem label="所在单位及部门:">
                                    <Input v-model="strategyProjectLeaderForm.department"></Input>
                                </FormItem>
                            </Col>
                            <Col :span="10">
                                <FormItem label="学历:">
                                    <Select v-model="strategyProjectLeaderForm.education">
                                        <Option value="1">大专</Option>
                                        <Option value="2">本科</Option>
                                        <Option value="3">硕士</Option>
                                        <Option value="4">博士</Option>
                                    </Select>
                                </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col :span="10">
                                <FormItem label="研究专长:">
                                    <Input
                                            v-model="strategyProjectLeaderForm.researchExpertise"
                                    ></Input>
                                </FormItem>
                            </Col>
                            <Col :span="10">
                                <FormItem label="学位:">
                                    <Input
                                            v-model="strategyProjectLeaderForm.bachelorOfScience"
                                    ></Input>
                                </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col :span="10">
                                <FormItem label="电子邮箱:">
                                    <Input v-model="strategyProjectLeaderForm.email"></Input>
                                </FormItem>
                            </Col>
                            <Col :span="10">
                                <FormItem label="民族:">
                                    <Input v-model="strategyProjectLeaderForm.nation"></Input>
                                </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col :span="10">
                                <FormItem label="职称:">
                                    <Input v-model="strategyProjectLeaderForm.position"></Input>
                                </FormItem>
                            </Col>
                            <Col :span="10">
                                <FormItem label="手机号码:">
                                    <Input
                                            v-model="strategyProjectLeaderForm.phoneNumber"
                                    ></Input>
                                </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col :span="10">
                                <FormItem label="邮编:">
                                    <Input v-model="strategyProjectLeaderForm.postcode"></Input>
                                </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col :span="15">
                                <FormItem label="通讯地址:">
                                    <Input
                                            v-model="strategyProjectLeaderForm.postalAddress"
                                    ></Input>
                                </FormItem>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>
            <div style="height: 380px" class="common_liucheng">
                <span>课题组主要成员</span>
                <div class="neirong">
                    <Form :model="strategyMembersForm" :label-width="140">
                        <Row>
                            <Col :span="10">
                                <FormItem label="姓名:">
                                    <Input v-model="strategyMembersForm.name"></Input>
                                </FormItem>
                            </Col>
                            <Col :span="10">
                                <FormItem label="学历:">
                                    <Select v-model="strategyMembersForm.education">
                                        <Option value="1">大专</Option>
                                        <Option value="2">本科</Option>
                                        <Option value="3">硕士</Option>
                                        <Option value="4">博士</Option>
                                    </Select>
                                </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col :span="10">
                                <FormItem label="职称:">
                                    <Input v-model="strategyMembersForm.title"></Input>
                                </FormItem>
                            </Col>
                            <Col :span="10">
                                <FormItem label="号码:">
                                    <Input v-model="strategyMembersForm.phoneNumber"></Input>
                                </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col :span="10">
                                <FormItem label="身份证号码:">
                                    <Input v-model="strategyMembersForm.idNumber"></Input>
                                </FormItem>
                            </Col>
                            <Col :span="10">
                                <FormItem label="单位名称:">
                                    <Input v-model="strategyMembersForm.companyName"></Input>
                                </FormItem>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>
            <div style="height: 390px" class="common_liucheng">
                <span>申报单位情况</span>
                <div class="neirong">
                    <Form :model="strategyDeclarationUnitForm" :label-width="140">
                        <Row>
                            <Col :span="10">
                                <FormItem label="单位名称:">
                                    <Input
                                            v-model="strategyDeclarationUnitForm.unitsName"
                                    ></Input>
                                </FormItem>
                            </Col>
                            <Col :span="10">
                                <FormItem label="性质:">
                                    <Input
                                            v-model="strategyDeclarationUnitForm.unitsCharacter"
                                    ></Input>
                                </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col :span="10">
                                <FormItem label="邮编:">
                                    <Input v-model="strategyDeclarationUnitForm.postcode"></Input>
                                </FormItem>
                            </Col>
                            <Col :span="10">
                                <FormItem label="社会统一信用代码:">
                                    <Input
                                            v-model="strategyDeclarationUnitForm.creditCode"
                                    ></Input>
                                </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col :span="23">
                                <FormItem label="通讯地址:">
                                    <Input
                                            v-model="strategyDeclarationUnitForm.postalAddress"
                                    ></Input>
                                </FormItem>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>
            <div style="height: 390px" class="common_liucheng">
                <span>合作单位情况</span>
                <div class="neirong">
                    <Form :model="strategyCooperationUnitForm" :label-width="140">
                        <Row>
                            <Col :span="10">
                                <FormItem label="单位名称:">
                                    <Input
                                            v-model="strategyCooperationUnitForm.unitsName"
                                    ></Input>
                                </FormItem>
                            </Col>
                            <Col :span="10">
                                <FormItem label="性质:">
                                    <Input
                                            v-model="strategyCooperationUnitForm.unitsCharacter"
                                    ></Input>
                                </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col :span="10">
                                <FormItem label="邮编:">
                                    <Input v-model="strategyCooperationUnitForm.postcode"></Input>
                                </FormItem>
                            </Col>
                            <Col :span="10">
                                <FormItem label="社会统一信用代码:">
                                    <Input
                                            v-model="strategyCooperationUnitForm.creditCode"
                                    ></Input>
                                </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col :span="23">
                                <FormItem label="通讯地址:">
                                    <Input
                                            v-model="strategyCooperationUnitForm.postalAddress"
                                    ></Input>
                                </FormItem>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>
            <div
                    style="height: 180px; display: flex"
                    class="next_style common_liucheng"
            >
                <div
                        style="height: 40px; width: 120px"
                        class="button_style"
                        @click="setIndexFlag()"
                >
                    下一步
                </div>

            </div>
        </div>

        <!--战略政策 其他信息-->
        <div v-show="nowIndex == 8">
            <Form :model="strategyExtraForm" :label-width="100">
                <div style="height: 470px" class="common_liucheng">
                    <span>基础研究</span>
                    <div class="neirong">
                        <Row>
                            <Col span="21">
                                <FormItem label="">
                                    <Input
                                            v-model="strategyExtraForm.basicResearch"
                                            type="textarea"
                                            :autosize="{ minRows: 10 }"
                                            placeholder="现有专家资源、平台、工作团队等条件"
                                    ></Input>
                                </FormItem>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div style="height: 470px" class="common_liucheng">
                    <span>工作方案</span>
                    <div class="neirong">
                        <Row>
                            <Col span="21">
                                <FormItem label="">
                                    <Input
                                            v-model="strategyExtraForm.workPlan"
                                            type="textarea"
                                            :autosize="{ minRows: 10 }"
                                            placeholder="项目的主要内容、基本思路和方法、实施计划及有关措施"
                                    ></Input>
                                </FormItem>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div style="height: 180px" class="next_style common_liucheng">
                    <div
                            style="height: 40px; width: 120px"
                            class="button_style"
                            @click="submit2('1')"
                    >
                        提交
                    </div>
                </div>
            </Form>
        </div>

        <!--战略政策 填报信息-->
        <div v-show="nowIndex == 6">
            <div style="height: 1000px" class="common_liucheng">
                <span>申报流程</span>
                <div class="neirong">
                    <p
                            style="
              text-align: center;
              font-size: 20px;
              font-family: Microsoft YaHei;
              display: block;
              color: rgb(51, 51, 51);
            "
                    >
                        {{ this.mainTitle }}
                    </p>
                    <p
                            style="
              text-align: center;
              font-size: 20px;
              font-family: Microsoft YaHei;
              display: block;
              margin-bottom: 65px;
              color: rgb(51, 51, 51);
            "
                    >
                    </p>
                    <Form :model="strategyFillForm" :label-width="170">
                        <FormItem label="项目名称:">
                            <Input v-model="strategyFillForm.projectName" type="text" disabled/>
                        </FormItem>
                        <FormItem label="申报单位:">
                            <Input v-model="strategyFillForm.declareEnter" type="text"/>
                        </FormItem>
                        <FormItem label="通讯地址:">
                            <Input v-model="strategyFillForm.postalAddress" type="text"/>
                        </FormItem>
                        <Row>
                            <Col span="15">
                                <FormItem label="联系人:">
                                    <Input v-model="strategyFillForm.contactUser" type="text"/>
                                </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col span="15">
                                <FormItem label="联系电话:">
                                    <Input v-model="strategyFillForm.contactTel" type="text"/>
                                </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col span="24">
                                <FormItem label="诚信承诺书:">
                                    <Input
                                            disabled
                                            id="strategy_promise"
                                            style="width: 30%"
                                            v-model="strategyFillForm.strategy_promise"
                                            type="text"
                                    />
                                    <Upload
                                            action=""
                                            :show-upload-list="false"
                                            :format="['jpg', 'png']"
                                            :before-upload="
                      (file) => handleUpload2(file, 'strategy_promise')
                    "
                                    >
                                        <Button style="padding-left: 20px">浏览</Button>
                                        <span style="padding-left:5px;vertical-align: text-top"
                                        >承诺书需加盖公章并扫描.jpg、.png格式上传</span
                                        >
                                    </Upload>
                                </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col span="24">
                                <FormItem label="申报单位意见书:">
                                    <Input
                                            disabled
                                            id="strategy_cooperationAgreement"
                                            style="width: 30%"
                                            v-model="strategyFillForm.strategy_proposal"
                                            type="text"
                                    />
                                    <Upload
                                            action=""
                                            :show-upload-list="false"
                                            :format="['jpg', 'png']"
                                            :before-upload="
                      (file) => handleUpload2(file, 'strategy_proposal')
                    "
                                    >
                                        <Button style="padding-left: 20px">浏览</Button>
                                        <span style="padding-left:5px;vertical-align: text-top"
                                        >承诺书需加盖公章并扫描.jpg、.png格式上传</span
                                        >
                                    </Upload>
                                </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col span="24">
                                <FormItem label="经费预算表:">
                                    <Input
                                            disabled
                                            id="strategy_budget"
                                            v-model="strategyFillForm.strategy_budget"
                                            type="text"
                                            style="width: 30%"
                                    />

                                    <Upload
                                            action=""
                                            :show-upload-list="false"
                                            :format="['jpg', 'png']"
                                            :before-upload="
                      (file) => handleUpload2(file, 'strategy_budget')
                    "
                                    >
                                        <Button style="padding-left: 20px">浏览</Button>
                                        <span style="padding-left:5px;vertical-align: text-top"
                                        >承诺书需加盖公章并扫描.jpg、.png格式上传</span
                                        >
                                    </Upload>
                                </FormItem>
                            </Col>
                        </Row>
                    </Form>
                    <div
                            style="height: 180px; border-bottom: none"
                            class="next_style common_liucheng"
                    >
                        <div
                                style="height: 40px; width: 120px"
                                class="button_style"
                                @click="nowIndex7()"
                        >
                            下一步
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!--战略政策  流程-->
        <div v-show="nowIndex == 5">
            <div style="height: 370px" class="common_liucheng">
                <span>申报流程</span>
                <div class="neirong" style="padding-left: 142px">
                    <img :src="process" alt="" style="width: 85%"/>
                </div>
            </div>
            <div style="height: 640px" class="common_liucheng">
                <span>填报说明</span>
                <div class="neirong" style="padding-left: 142px">
                    <p style="line-height: 38px">
                        （一）申报单位是在中国境内注册具有独立法人资格的高校、科研院所、协会及企业。
                    </p>
                    <p style="line-height: 38px">
                        （二）申报项目内容应符合国家、地方政策要求，符合宁夏枸杞产业发展方向，研究目标明确、技术路线科学、经费预算合理。
                    </p>
                    <p style="line-height: 38px">
                        （三）申报项目负责人须为申报单位在职人员，大专以上学历或中级及中级以上职称，实际主持项目研究工作。
                    </p>
                    <p style="line-height: 38px">（四）申报单位须对所申报材料的真实性、合法性、有效性负责。</p>
                    <p style="line-height: 38px">（五）两个及多个单位申报项目时需提交单位合作协议。</p>
                    <p style="line-height: 38px">（六）产业化项目支持2019年10月1日以后备案开工的项目。</p>
                    <p style="line-height: 38px">（七）有下列情况之一的不得申报：</p>
                    <p style="line-height: 38px">1.申报项目知识产权不清或存在知识产权纠纷。</p>
                    <p style="line-height: 38px">2.内容相同或相近的项目重复申报或多头申报。</p>
                    <p style="line-height: 38px">
                        一、申请书内各项内容应实事求是，认真填写，表达明确。外来语要同时用原文和中文表达，第一次出现的缩略词，须说明全称。
                    </p>
                    <p style="line-height: 38px">二、申请书中各栏不得出现空白，如没有内容，可填“无”</p>
                    <p style="line-height: 38px">三、项目应通过所在单位推荐申报，未经推荐的项目不予受理。</p>
                </div>
            </div>
            <div style="height: 240px" class="common_liucheng">
                <span>模板下载</span>
                <div class="neirong" style="padding-left: 142px">
                    <span>诚信承诺书 (模板).doc</span>
                    <span class="download_span"
                    ><a
                            href="https://cnipy-public.oss-cn-shanghai.aliyuncs.com/cnipy/web-static/zndec/doc/%E8%AF%9A%E4%BF%A1%E6%89%BF%E8%AF%BA%E4%B9%A6%EF%BC%88%E6%A8%A1%E7%89%88%EF%BC%89.docx"
                    >点击下载</a
                    ></span
                    >
                    <span>申报单位意见书 (模板).doc</span>
                    <span class="download_span"
                    ><a
                            href="https://cnipy-public.oss-cn-shanghai.aliyuncs.com/cnipy/web-static/zndec/doc/%E7%94%B3%E6%8A%A5%E5%8D%95%E4%BD%8D%E6%84%8F%E8%A7%81%E4%B9%A6%EF%BC%88%E6%A8%A1%E7%89%88%EF%BC%89.docx"
                    ></a
                    ></span>
                    <span>项目经费支出预算 (模板).doc</span>
                    <span class="download_span"
                    ><a
                            href="https://cnipy-public.oss-cn-shanghai.aliyuncs.com/cnipy/web-static/zndec/doc/%E9%A1%B9%E7%9B%AE%E7%BB%8F%E8%B4%B9%E9%A2%84%E7%AE%97%E8%A1%A8%EF%BC%88%E6%A8%A1%E7%89%88%EF%BC%89.docx"
                    >点击下载</a
                    ></span
                    >
                </div>
            </div>
            <div style="height: 180px" class="next_style common_liucheng">
                <div
                        style="height: 40px; width: 120px"
                        class="button_style"
                        @click="
            nowIndex = 6;

            $parent.changeHeight();
          "
                >
                    下一步
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        getProjectList,
        saveBase,
        saveMain,
        continueShen,
        uploadImg,
        saveOther,
    } from "../../../plugins/api/sciencePolicy";
    import {
        isCorrectPassword,
        isPhoneNumber,
        successToast,
        warnToast,
        isNumber,
        errorToast,
    } from "@/plugins/tools/util";

    import process from "@/assets/image/science/process.png";

    export default {
        name: "SciencePolicyCom",
        data() {
            return {
                projectConfigId: "",
                index8Flag: false,
                mainTitle: "",
                startTime: "",
                endTime: "",
                startOption: {},
                endOption: {},
                agreementUrls: [],
                projectId: 10,
                total: 10,
                params: {
                    keyword: "",
                    pageNum: 1,
                    pageSize: 10,
                },
                next4Flag: false,
                process: process,
                nowIndex: 0,
                deleteModal: false,
                columns12: [
                    {
                        title: "项目名称",
                        key: "projectName",
                        align: "center",
                        render: (h, params) => {
                            return h('div', [
                                h('span', {
                                    style: {
                                        display: '-webkit-box',
                                        width: '100%',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        '-webkit-line-clamp': '2',
                                        '-webkit-box-orient': 'vertical'
                                    },
                                    domProps: {
                                        title: params.row.projectName
                                    }
                                }, params.row.projectName)
                            ])
                        }
                    },
                    {
                        title: "申报时间",
                        key: "createTime",
                        align: "center",
                        width: "300",
                    },
                    {
                        title: "操作",
                        slot: "action",
                        align: "center",
                        width: "300",
                    },
                ],
                data6: [],
                // 非战略资讯类基础信息Form
                basicInformationForm: {
                    projectCategory: [],
                    projectType: [],
                    totalBudget: "",
                    applySupport: "",
                    researchContents: "",
                    date: {
                        startTime: "",
                        endTime: "",
                    },
                },
                //负责人Form
                projectLeaderForm: {
                    leaderName: "",
                    gender: "0",
                    idNumber: "",
                    education: "0",
                    phoneNumber: "",
                    position: "",
                    birthday: "",
                    major: "",
                    email: "",
                },
                //课题主要成员form
                membersForm: {
                    name: "",
                    education: "",
                    title: "",
                    phoneNumber: "",
                    idNumber: "",
                    companyName: "",
                },
                // 申报单位情况
                declarationUnitForm: {
                    unitsName: "",
                    unitsCharacter: "",
                    postcode: "",
                    creditCode: "",
                    postalAddress: "",
                },
                //合作单位
                cooperationUnitForm: {
                    unitsName: "",
                    unitsCharacter: "",
                    postcode: "",
                    creditCode: "",
                    postalAddress: "",
                },
                //指标
                textForm: {
                    text: "",
                },
                //其他信息
                extraForm: {
                    projectSignificance: "",
                    technologyStatus: "",
                    projectObjectives: "",
                    projectTasks: "",
                    expectedProspects: "",
                    researchBasis: "",
                    implementationPlan: "",
                    linkedAction: "",
                    personResume: "",
                },
                //填报信息
                fillForm: {
                    projectName: "",
                    declareEnter: "",
                    postalAddress: "",
                    contactUser: "",
                    contactTel: "",
                    promise: "",
                    proposal: "",
                    budget: "",
                    cooperationAgreement: "",
                },

                //战略资讯基础信息
                strategyBasicFrom: {
                    projectCategory: [],
                    expectedResults: "",
                    achievementsWords: "",
                    applyFunds: "",
                    planCompletedTime: "",
                },
                //负责人Form
                strategyProjectLeaderForm: {
                    bachelorOfScience: "",
                    department: "",
                    education: 1,
                    email: "",
                    gender: 1,
                    leaderName: "",
                    nation: "",
                    phoneNumber: "",
                    position: "",
                    postalAddress: "",
                    postcode: "",
                    projectId: "",
                    researchExpertise: "",
                },
                //课题主要成员form
                strategyMembersForm: {
                    name: "",
                    education: "",
                    title: "",
                    phoneNumber: "",
                    idNumber: "",
                    companyName: "",
                },
                // 申报单位情况
                strategyDeclarationUnitForm: {
                    unitsName: "",
                    unitsCharacter: "",
                    postcode: "",
                    creditCode: "",
                    postalAddress: "",
                },
                //合作单位
                strategyCooperationUnitForm: {
                    unitsName: "",
                    unitsCharacter: "",
                    postcode: "",
                    creditCode: "",
                    postalAddress: "",
                },

                // 战略咨询其他
                strategyExtraForm: {
                    basicResearch: "",
                    workPlan: "",
                },
                // 战略填报
                strategyFillForm: {
                    projectName: "",
                    declareEnter: "",
                    postalAddress: "",
                    contactUser: "",
                    contactTel: "",
                    strategy_proposal: '',
                    strategy_budget: '',
                    strategy_promise: ''

                },
            };
        },
        mounted() {
            this.getProject();
        },
        watch: {
            startTime() {
                let self = this;
                this.endOption = {
                    disabledDate(date) {
                        if (self.startTime) {
                            return date && date.valueOf() < self.startTime;
                        }
                    },
                };
            },
            endTime() {
                let self = this;
                this.startOption = {
                    disabledDate(date) {
                        if (self.endTime) {
                            return date && date.valueOf() > self.endTime;
                        }
                    },
                };
            },
        },
        methods: {
            isNum(value, item, key, name) {
                let res = isNumber(value);
                if (!res) {
                    this[item][key] = 0;
                    warnToast(name + '需填写数字')
                }
            },
            async setIndexFlag() {
                let baseInfo = {
                    applyFunds: this.strategyBasicFrom.applyFunds,
                    projectId: this.projectId,
                    planCompletedTime: this.strategyBasicFrom.planCompletedTime,
                    expectedResults: this.strategyBasicFrom.expectedResults,
                    achievementsWords: this.strategyBasicFrom.achievementsWords
                };
                baseInfo.projectCategory = this.strategyBasicFrom.projectCategory[0];

                let leaderForm = JSON.parse(
                    JSON.stringify(this.strategyProjectLeaderForm)
                );
                leaderForm.projectId = this.projectId;
                let mainMember = [
                    {
                        companyName: this.strategyMembersForm.companyName,
                        idNumber: this.strategyMembersForm.idNumber,
                        name: this.strategyMembersForm.name,
                        phoneNumber: this.strategyMembersForm.phoneNumber,
                        projectId: this.projectId,
                        title: this.strategyMembersForm.title,
                    },
                ];
                mainMember[0].education = this.strategyMembersForm.education;
                let cooperationUnits = [this.strategyCooperationUnitForm];
                let declareUnits = [this.strategyDeclarationUnitForm];
                for (let item in baseInfo) {
                    if (baseInfo[item] === "") {
                        warnToast("请将基础信息填写完整");
                        return;
                    }
                }
                for (let item in cooperationUnits[0]) {
                    if (cooperationUnits[0][item] === "") {
                        warnToast("请将合作信息填写完整");
                        return;
                    }
                }
                for (let item in declareUnits[0]) {
                    if (declareUnits[0][item] === "") {
                        warnToast("请将申报信息填写完整");
                        return;
                    }
                }
                for (let item in mainMember[0]) {
                    if (mainMember[0][item] === "") {
                        warnToast("请将主要成员信息填写完整");
                        return;
                    }
                }
                for (let item in leaderForm) {
                    if (leaderForm[item] === "") {
                        warnToast("请将负责人信息填写完整");
                        return;
                    }
                }
                let data = {
                    baseInfo: baseInfo,
                    cooperationUnits: cooperationUnits,
                    declareUnits: declareUnits,
                    leaderInfo: leaderForm,
                    topicGroup: mainMember,
                    projectId: this.projectId,
                };
                let json = await saveBase(data);
                if (json.code == 200) {
                    this.index8Flag = true;
                    this.nowIndex = 8;
                    document.body.scrollTop = 0
                    document.documentElement.scrollTop = 0
                    this.$parent.changeHeight();
                } else {
                    errorToast(json.message);
                }
            },

            // 战略提交
            async submit2(status) {
                let data = {
                    workPlan: this.strategyExtraForm.workPlan,
                    basicResearch: this.strategyExtraForm.basicResearch,
                    status: status,
                    projectId: this.projectId,
                };
                for (let key in data) {
                    if (data[key] === "") {
                        warnToast("请将信息填写完成");
                        return;
                    }
                }


                let json = await saveOther(data);
                if (json.code == 200) {
                    successToast("操作成功");
                    if (status == 1) {
                        for (let key in this.extraForm) {
                            this.strategyExtraForm[key] = "";
                        }
                    }
                    this.nowIndex == 0;
                    this.$parent.changeHeight()
                    location.reload();

                } else {
                    errorToast(json.message);
                }
            },

            // 非战略提交
            async submit(status) {
                let data = {
                    expectedProspects: this.extraForm.expectedProspects,
                    implementationPlan: this.extraForm.implementationPlan,
                    linkedAction: this.extraForm.linkedAction,
                    personResume: this.extraForm.personResume, //负责人履历(非战略咨询类)
                    projectId: this.projectId,
                    projectObjectives: this.extraForm.projectObjectives,
                    projectSignificance: this.extraForm.projectSignificance,
                    projectTasks: this.extraForm.projectTasks,
                    researchBasis: this.extraForm.researchBasis,
                    status: status,
                    technologyStatus: this.extraForm.technologyStatus,
                };
                for (let key in data) {
                    if (data[key] === "") {
                        warnToast("请将信息填写完成");
                        return;
                    }
                }
                let json = await saveOther(data);
                if (json.code == 200) {
                    successToast("操作成功");
                    if (status == 1) {
                        for (let key in this.extraForm) {
                            this.extraForm[key] = "";
                        }
                        location.reload();
                        this.$parent.changeHeight();
                    }

                } else {
                    errorToast(json.message);
                }
            },

            //多选变单选
            dataChange(data) {
                if (data.length > 1) {
                    this.basicInformationForm.projectCategory = [];
                    let index = String(data.length - 1);
                    this.basicInformationForm.projectCategory = [data[index]];
                }
            },
            dataChange1(data) {
                if (data.length > 1) {
                    this.basicInformationForm.projectType = [];
                    let index = String(data.length - 1);
                    this.basicInformationForm.projectType = [data[index]];
                }
            },
            dataChange2(data) {
                if (data.length > 1) {
                    this.strategyBasicFrom.projectCategory = [];
                    let index = String(data.length - 1);
                    this.strategyBasicFrom.projectCategory = [data[index]];
                }
            },
            // 非战略翻页
            // next4() {
            //   if (this.next4Flag === false) {
            //     warnToast("请先暂存");
            //   } else {
            //
            //   }
            // },

            // 提交基础信息
            async nowIndex4() {
                // 基础信息数据
                let baseInfo = {
                    applySupport: this.basicInformationForm.applySupport,
                    researchEndTime: this.endTime,
                    researchStartTime: this.startTime,
                    totalBudget: this.basicInformationForm.totalBudget,
                    researchContents: this.basicInformationForm.researchContents,
                    projectId: this.projectId,
                };
                baseInfo.projectCategory = this.basicInformationForm.projectCategory[0];
                baseInfo.projectType = this.basicInformationForm.projectType[0];
                let cooperationUnits = [this.cooperationUnitForm];
                let declareUnits = [this.declarationUnitForm];
                // leader
                let leaderInfo = JSON.parse(JSON.stringify(this.projectLeaderForm));
                // 主要成员
                let mainMember = [
                    {
                        companyName: this.membersForm.companyName,
                        idNumber: this.membersForm.idNumber,
                        name: this.membersForm.name,
                        phoneNumber: this.membersForm.phoneNumber,
                        projectId: this.projectId,
                        title: this.membersForm.title,
                    },
                ];
                mainMember[0].education = this.membersForm.education;
                for (let item in baseInfo) {
                    if (baseInfo[item] === "") {
                        warnToast("请将基础信息填写完整");
                        return;
                    }
                }
                for (let item in cooperationUnits[0]) {
                    if (cooperationUnits[0][item] === "") {
                        warnToast("请将合作信息填写完整");
                        return;
                    }
                }
                for (let item in declareUnits[0]) {
                    if (declareUnits[0][item] === "") {
                        warnToast("请将申报信息填写完整");
                        return;
                    }
                }
                for (let item in mainMember[0]) {
                    if (mainMember[0][item] === "") {
                        warnToast("请将主要成员信息填写完整");
                        return;
                    }
                }
                for (let item in leaderInfo) {
                    if (leaderInfo[item] === "") {
                        warnToast("请将负责人信息填写完整");
                        return;
                    }
                }
                let data = {
                    baseInfo: baseInfo,
                    cooperationUnits: cooperationUnits,
                    declareUnits: declareUnits,
                    leaderInfo: leaderInfo,
                    topicGroup: mainMember,
                    projectId: this.projectId,
                };
                let json = await saveBase(data);
                if (json.code == 200) {
                    this.next4Flag = true;
                    this.nowIndex = 4;
                    this.$parent.changeHeight();
                    document.body.scrollTop = 0
                    document.documentElement.scrollTop = 0
                } else {
                    errorToast(json.message);
                }
            },

            // 提交照片
            async nowIndex3() {
                scrollTo(0, 0);
                let data = {
                    agreementUrls: this.agreementUrls,
                    budgetTable: this.budgetTable,
                    contactTel: this.fillForm.contactTel,
                    contactUser: this.fillForm.contactUser,
                    declareEnter: this.fillForm.declareEnter,
                    letterOfCommitment: this.letterOfCommitment,
                    postalAddress: this.fillForm.postalAddress,
                    projectName: this.fillForm.projectName,
                    projectConfigId: Number(this.projectConfigId),
                    projectType: 0,
                    writtenOpinions: this.writtenOpinions,
                    id: this.fillForm.id
                };
                let json = await saveMain(data);
                if (json.code == 200) {
                    this.projectId = json.data.projectId;
                    this.nowIndex = 3;
                    document.body.scrollTop = 0
                    document.documentElement.scrollTop = 0
                } else {
                    errorToast(json.message);
                }
                this.$parent.changeHeight();
            },

            async nowIndex7() {
                let data = {
                    budgetTable: this.budgetTable,
                    contactTel: this.strategyFillForm.contactTel,
                    contactUser: this.strategyFillForm.contactUser,
                    declareEnter: this.strategyFillForm.declareEnter,
                    letterOfCommitment: this.letterOfCommitment,
                    postalAddress: this.strategyFillForm.postalAddress,
                    projectName: this.strategyFillForm.projectName,
                    projectType: 1,
                    projectConfigId: Number(this.projectConfigId),
                    writtenOpinions: this.writtenOpinions,
                    id: this.strategyFillForm.id
                };

                let json = await saveMain(data);
                if (json.code == 200) {
                    this.projectId = json.data.projectId;
                    this.nowIndex = 7;
                    document.body.scrollTop = 0
                    document.documentElement.scrollTop = 0
                } else {
                    errorToast(json.message);
                }
                this.$parent.changeHeight();
            },
            // 搜索
            search() {
                this.params.pageNum = 1;
                this.getProject();
            },

            // 翻页
            changePage(index) {
                this.params.pageNum = index;
                this.getProject();
            },

            //  上传
            async handleUpload(file, name) {
                const FileExt = file.name.replace(/.+\./, ""); //取得文件的后缀名
                if (FileExt == "png" || FileExt == "jpg") {
                    const reader = new FileReader();
                    //将文件读取为 DataURL 以data:开头的字符串
                    let code = "";
                    if (name == "proposal") {
                        this.fillForm[name] += " " + file.name;
                    } else {
                        this.fillForm[name] = file.name;
                        this.$nextTick(() => {
                            this.$set(this.fillForm, name, file.name)
                        })
                        this.$forceUpdate()
                    }

                    let _that = this;
                    reader.readAsDataURL(file);
                    reader.onload = (e) => {
                        // 读取到的图片base64 数据编码 将此编码字符串传给后台即可
                        code = e.target.result;

                        let data = {
                            imageBase64: code,
                            dataType: 0,
                        };
                        uploadImg(data).then((res) => {
                            successToast("上传成功")
                            if (res.code == "200") {
                                switch (name) {
                                    case "cooperationAgreement":
                                        _that.writtenOpinions = res.data;
                                        break;
                                    case "promise":
                                        _that.letterOfCommitment = res.data;
                                        break;
                                    case "budget":
                                        _that.budgetTable = res.data;
                                        break;
                                    case "proposal":
                                        _that.agreementUrls.push(res.data);
                                }
                            } else {
                                errorToast(res.message);
                            }
                        });
                    };
                } else {
                    warnToast("只支持jpg和png");
                    this.file = "";
                }

                // if(json.code == 200){

                // }
            },

            // 上传2
            async handleUpload2(file, name) {
                const FileExt = file.name.replace(/.+\./, ""); //取得文件的后缀名
                if (FileExt == "png" || FileExt == "jpg") {
                    const reader = new FileReader();
                    //将文件读取为 DataURL 以data:开头的字符串
                    let code = "";
                    this.$set(this.strategyFillForm, name, file.name)
                    this.$forceUpdate()
                    let _that = this;
                    reader.readAsDataURL(file);
                    reader.onload = (e) => {
                        // 读取到的图片base64 数据编码 将此编码字符串传给后台即可
                        code = e.target.result;

                        let data = {
                            imageBase64: code,
                            dataType: 0,
                        };
                        uploadImg(data).then((res) => {
                            if (res.code == "200") {
                                successToast("上传成功")
                                switch (name) {
                                    case "strategy_proposal":
                                        _that.writtenOpinions = res.data;
                                        break;
                                    case "strategy_promise":
                                        _that.letterOfCommitment = res.data;
                                        break;
                                    case "strategy_budget":
                                        _that.budgetTable = res.data;
                                        break;
                                }
                            } else {
                                errorToast(res.message);
                            }
                        });
                    };
                } else {
                    warnToast("只支持jpg和png");
                    this.file = "";
                }
            },
            // 列表
            async getProject() {
                let json = await getProjectList(this.params);
                if (json.code == 200) {
                    this.total = json.data.total;
                    this.data6 = json.data.list;
                    this.data6.forEach((item, index) => {
                        let dateee = new Date(item.endTime).toJSON();
                        item.endTime = new Date(+new Date(dateee) + 8 * 3600 * 1000)
                            .toISOString()
                            .replace(/T/g, " ")
                            .replace(/\.[\d]{3}Z/, "")
                            .split(" ")[0];
                        let dateee2 = new Date(item.startTime).toJSON();
                        item.startTime = new Date(+new Date(dateee2) + 8 * 3600 * 1000)
                            .toISOString()
                            .replace(/T/g, " ")
                            .replace(/\.[\d]{3}Z/, "")
                            .split(" ")[0];
                        item.createTime = item.startTime + "~" + item.endTime
                    });
                }
                this.$parent.changeHeight();
            },

            declarationGuide(row) {
                this.projectConfigId = row.id
                this.mainTitle = row.projectName;
                this.fillForm.projectName = row.projectName;
                this.strategyFillForm.projectName = row.projectName
                if (row.projectType == 0) {
                    this.nowIndex = 1;
                    scrollTo(0, 0);
                } else {
                    this.nowIndex = 5;
                    scrollTo(0, 0);
                }

                this.$parent.changeHeight();
            },

            goDeclarate(row) {
                this.projectConfigId = row.id
                this.mainTitle = row.projectName;
                if (row.projectType == 0) {
                    this.nowIndex = 2;
                    this.fillForm.projectName = this.mainTitle;
                    document.body.scrollTop = 0;
                    document.documentElement.scrollTop = 0;
                } else {
                    this.nowIndex = 6;
                    this.strategyFillForm.projectName = this.mainTitle;
                    document.body.scrollTop = 0;
                    document.documentElement.scrollTop = 0;
                }

                this.$parent.changeHeight();
            },
            async continueDeclarate(row) {
                this.projectConfigId = row.id;
                let json = await continueShen({projectId: row.projectId});
                if (json.code == 200) {
                    if (row.projectType == 0) {
                        this.nowIndex = 2;
                        this.fillForm = JSON.parse(JSON.stringify(json.data.smsProject));
                        this.mainTitle = this.fillForm.projectName
                        this.letterOfCommitment = json.data.smsProject.letterOfCommitment;
                        this.writtenOpinions = json.data.smsProject.writtenOpinions;
                        this.budgetTable = json.data.smsProject.budgetTable;
                        this.agreementUrls = json.data.smsProject.agreementUrls ? json.data.smsProject.agreementUrls : [];
                        this.fillForm.promise = json.data.smsProject.letterOfCommitment;
                        this.fillForm.budget = json.data.smsProject.budgetTable;
                        this.fillForm.cooperationAgreement = json.data.smsProject.writtenOpinions;
                        this.fillForm.id = row.projectId;
                        this.fillForm.proposal = json.data.smsProject.agreementUrls ? json.data.smsProject.agreementUrls.join(',') : "";
                        if (json.data.baseInfo) {
                            this.basicInformationForm = json.data.baseInfo
                            this.startTime = json.data.baseInfo.researchStartTime;
                            this.endTime = json.data.baseInfo.researchEndTime;
                            this.basicInformationForm.projectCategory = [json.data.baseInfo.projectCategory + ""]
                            this.basicInformationForm.projectType = [json.data.baseInfo.projectType + ""]
                        }
                        if (json.data.leaderInfo) {
                            this.projectLeaderForm = json.data.leaderInfo;

                            this.projectLeaderForm.gender = json.data.leaderInfo.gender + "";
                            this.projectLeaderForm.education = json.data.leaderInfo.education + "";
                        }
                        if (json.data.baseInfo) {
                            this.membersForm = json.data.topicGroup[0]
                            this.membersForm.education = json.data.topicGroup[0].education + "";
                        }
                        if (json.data.declareUnits) {
                            this.declarationUnitForm = json.data.declareUnits[0]
                        }
                        if (json.data.cooperationUnits) {
                            this.cooperationUnitForm = json.data.cooperationUnits[0]
                        }
                    } else {
                        this.nowIndex = 6;
                        this.strategyFillForm = JSON.parse(JSON.stringify(json.data.smsProject));
                        this.mainTitle = this.strategyFillForm.projectName;
                        this.letterOfCommitment = json.data.smsProject.letterOfCommitment;
                        this.writtenOpinions = json.data.smsProject.writtenOpinions;
                        this.budgetTable = json.data.smsProject.budgetTable;
                        this.strategyFillForm.strategy_proposal = json.data.smsProject.writtenOpinions;
                        this.strategyFillForm.strategy_budget = json.data.smsProject.budgetTable;
                        this.strategyFillForm.strategy_promise = json.data.smsProject.letterOfCommitment;
                        this.strategyFillForm.id = row.projectId;
                        if (json.data.baseInfo) {
                            this.strategyBasicFrom = json.data.baseInfo
                            this.strategyBasicFrom.projectCategory = [json.data.baseInfo.projectCategory + ""]

                        }
                        if (json.data.leaderInfo) {
                            this.strategyProjectLeaderForm = json.data.leaderInfo;
                            this.strategyProjectLeaderForm.gender = json.data.leaderInfo.gender + "";
                            this.strategyProjectLeaderForm.education = json.data.leaderInfo.education + "";
                        }
                        if (json.data.baseInfo) {
                            this.strategyMembersForm = json.data.topicGroup[0]
                            this.strategyMembersForm.education = json.data.topicGroup[0].education + "";
                        }
                        if (json.data.declareUnits) {
                            this.strategyDeclarationUnitForm = json.data.declareUnits[0]
                        }
                        if (json.data.cooperationUnits) {
                            this.strategyCooperationUnitForm = json.data.cooperationUnits[0]
                        }

                    }
                    this.$parent.changeHeight();
                } else {
                    warnToast(json.message)
                }


            },
        },
    };
</script>

<style scoped lang="scss">
    /deep/ .ivu-input-wrapper {
        display: inline-block !important;
        width: 79%;
    }

    .download_span {
        color: #36c2cf;
    }

    .ivu-upload {
        display: inline-block;
        vertical-align: top;
        height: 40px;
        margin-left: -10px;
    }

    .ivu-btn {
        background-color: rgb(227, 229, 235);
        height: 40px;
        outline: none;
        border: 0 none;
    }

    /deep/ .ivu-table-border td,
    .ivu-table-border th {
        border-right: none;
    }

    /deep/ .ivu-table-tbody tr:nth-child(2n) td {
        background-color: #f3fcfc !important;
    }

    .common_liucheng:nth-child(1) {
        border: none;
    }

    .common_liucheng {
        border-top: 2px dotted #f5f5f5;
        width: 95%;
        position: relative;
        left: 50%;
        transform: translateX(-50%);

        & > span {
            display: block;
            padding-left: 5px;
            padding-top: 38px;
            font-size: 18px;
            font-family: Microsoft YaHei;
            color: rgb(51, 51, 51);
            margin-bottom: 60px;
            font-weight: bold;
        }
    }

    .next_style {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .right_real_content {
        margin-bottom: 50px;
        width: 95%;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        margin-top: 40px;
        overflow: hidden;
    }

    .right_header {
        width: 95%;
        padding-top: 20px;
        height: 140px;
        position: relative;
        margin-bottom: 40px !important;
        left: 50%;
        transform: translateX(-50%);
        border-bottom: 2px dotted #f5f5f5;
        overflow: hidden;

        span {
            display: block;
            margin-bottom: 20px;
        }
    }

    .button_style {
        display: inline-block;
        background-color: #1767E0;
        z-index: 51;
        text-align: center;
        font-size: 14px;
        color: white;
        cursor: pointer;
        padding: 0 27px;
        line-height: 32px;
        height: 32px;
        border-radius: 4px;
    }

    .input_search,
    .input_search:focus {
        border-width: 1px;
        border-color: rgb(55, 197, 207);
        border-style: solid;
        border-radius: 2px;
        background-color: rgb(255, 255, 255);
        box-shadow: 6px 0px 15px 0px rgba(127, 127, 127, 0.1);
        width: 300px;
        height: 40px;
        outline: none;
    }

    .neirong span {
        display: inline-block;
        margin-right: 20px;
    }

    .input_search {
        border-width: 1px;
        border-color: #dcdee2;
        border-style: solid;
        border-radius: 2px;
        background-color: rgb(255, 255, 255);
        box-shadow: 6px 0px 15px 0px rgba(127, 127, 127, 0.1);
        width: 300px;
        height: 40px;
        outline: none;
    }

    .input_search:focus {
        border-width: 1px;
        border-color: rgb(55, 197, 207);
        border-style: solid;
        border-radius: 2px;
        background-color: rgb(255, 255, 255);
        box-shadow: 6px 0px 15px 0px rgba(127, 127, 127, 0.1);
        width: 300px;
        height: 40px;
        outline: none;
    }
</style>
